import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import ErrorMessage from "../components/userfeedback/ErrorMessage";
import Loader from "../components/userfeedback/Loader";
import Navigation from "./partials/Navigation";
import SlideShowCard from "../components/card/SlideShowCard";

// hooks
import useDeleteSlideshow from "../hooks/useDeleteSlideshow";
import useGetSlideshows from "../hooks/useSlideshows";

const HomePage = () => {
  const { data, isError: isErrorOnRead, isLoading } = useGetSlideshows();
  const history = useHistory();

  const { mutate: deleteById, isError: isErrorOnDelete } = useDeleteSlideshow();

  const handleClick = (id) => {
    history.push(`/slideshow/${id}`);
  };

  return (
    <>
      <Navigation />
      {isErrorOnRead && (
        <ErrorMessage width={"80%"}>
          Something went wrong, maybe try logging in
        </ErrorMessage>
      )}
      {isErrorOnDelete && (
        <ErrorMessage width={"80%"}>Something went wrong</ErrorMessage>
      )}
      <div className="slideShowCardWrapper">
        {isLoading && <Loader />}
        {data && data.length < 1 && (
          <ErrorMessage>
            No slideshows here yet, go and create one{" "}
            <Link to="create">here</Link>
          </ErrorMessage>
        )}
        {data &&
          data.map((slideshow, i) => {
            return (
              <SlideShowCard
                deleteById={deleteById}
                cardEventHandlerOne={handleClick}
                key={i}
                data={slideshow}
                page={"Play"}
              />
            );
          })}
      </div>
    </>
  );
};
export default HomePage;
