const Description = ({ data, page }) => {
  const getDomain = (url) => {
    let domain;
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    } else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    return domain;
  };

  return (
    <div className="desc text-start">
      {page === "Play" && (
        <>
          <h3>
            {data.Title.length < 15 ? data.Title : data.Title.slice(0, 15)}
          </h3>
          <p>
            {data.Description.length < 30
              ? data.Description
              : data.Description.slice(0, 30) + " ..."}
          </p>
          <p>{"Total slides: " + data.TotalSlides}</p>
          <p>{data.Author}</p>
        </>
      )}
      {page === "Edit" && data.Location && (
        <>
          <h3>
            {data.Location.length < 15 ? data.Location : data.Location.slice(0, 15)}
          </h3>
          <h4>
            {data.Type === "freeRooms" ? "Available rooms" : "Customer meeting info"}
          </h4>
        </>
      )}
      {page === "Edit" && data.Website && (
        <>
          <h4>
            {data.Website.length < 15 ? getDomain(data.Website) : getDomain(data.Website)}
          </h4>
        </>
      )}
    </div>
  );
};

export default Description;
