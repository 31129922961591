import { useState, useEffect } from "react";
import FormButton from "./FormButton";

const SlideShowForm = ({ createSlideshow }) => {
  const [disable, setDisable] = useState(true);
  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: ""
  });

  useEffect(() => {
    // when all fields has a value, undisable the button
    if (inputs.one !== "" && inputs.two !== "" && inputs.three !== "") {
      setDisable(false);
    }
  }, [inputs]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      title: inputs.one.trim(),
      desc: inputs.two.trim(),
      author: inputs.three.trim(),
    };

    createSlideshow(data);
  };

  return (
    <>
      <form className="nameForm" onSubmit={handleSubmit}>
        <h4 className="my-4">Name your slideshow</h4>
        <input
          type="text"
          name="one"
          placeholder="Pick a title"
          onChange={(e) => handleChange(e)}
          required
        />
        <input
          type="text"
          name="two"
          placeholder="Describe your slideshow"
          onChange={(e) => handleChange(e)}
          required
        />
        <input
          type="text"
          name="three"
          placeholder="Author"
          onChange={(e) => handleChange(e)}
          required
        />
        <FormButton value={"Next"} disable={disable} userEvent={handleSubmit} />
      </form>
    </>
  );
};

export default SlideShowForm;
