import { Alert } from "react-bootstrap";

const ErrorMessage = ({ children, width }) => {
  return (
    <Alert
      style={{ width }}
      variant="warning"
      className="text-center mx-auto my-3"
    >
      {children}
    </Alert>
  );
};

export default ErrorMessage;

ErrorMessage.defaultProps = {
  width: "100%",
};
