import { useState } from "react";

const useTab = (defaultValue) => {
  const [tabIndex, setTabIndex] = useState(defaultValue);

  const setIndex = (index) => setTabIndex(index)

  return [tabIndex, setIndex];
};

export default useTab;
