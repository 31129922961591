import { Link } from "react-router-dom";
import Navigation from "./partials/Navigation";
const NotFoundPage = () => {
  return (
    <>
      <Navigation />
      <div className="text-center">
      <h1 className="m-4">
        The page you're looking for does not exist.
      </h1>
      <Link className="mt-4" to="/slideshows">
        <h5>Go back to homepage<span style={{fontSize: "1.5em", marginLeft: '0.5em'}}>🏠</span> </h5>
      </Link>
      </div>
    </>
  );
};

export default NotFoundPage;
