import { useEffect, useState } from "react";
import UploadButton from "./UploadButton";

// hooks
import useExternalMeetingInfo from "../../hooks/useExternalMeetingInfo";
import useCreateSlide from "../../hooks/useCreateSlide";

// components
import ErrorMessage from "../userfeedback/ErrorMessage";
import Loader from "../userfeedback/Loader";

const ExternalMeetingForm = ({ setLocation, userEvent }) => {
  const { data, isLoading, isError } = useExternalMeetingInfo();

  useEffect(() => {
    if (data) {
      setLocation(data[0].Name);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // here goes the api call to make an eventSlide
    userEvent();
  };

  return (
    <>
      {isError && <ErrorMessage>Something went wrong</ErrorMessage>}
      {isLoading && <Loader />}
      <form className="meetingForm" onSubmit={handleSubmit}>
        <select
          name="office"
          onChange={(e) => {
            //setOffice(e.target.value);
            setLocation(e.target.value);
            userEvent(true);
          }}
        >
          {data?.map((item, i) => {
            return <option key={i}>{item.Name}</option>;
          })}
        </select>
        {/* <UploadButton
          data={isLoading ? null : true}
          userEvent={handleSubmit}
          //isLoading={isLoading}
        /> */}
      </form>
    </>
  );
};

export default ExternalMeetingForm;
