import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, } from "@fortawesome/free-solid-svg-icons";

const UserFeedback = ({ isSuccess, isLoading }) => {

  return (
    <>
    <div className="user-feedback">
    {isLoading && <Loader />  }
    {isSuccess && <FontAwesomeIcon className="icon" icon={faCheck} size="3x" />}
    </div>  
    </>
  );
};

export default UserFeedback;
