import { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//hooks
import useToggle from "../../hooks/useToggle";
import useExplore from "../../hooks/useExplore";

// components
import ErrorMessage from "../userfeedback/ErrorMessage";
import GalleryCard from "../card/GalleryCard";
import Loader from "../userfeedback/Loader";

const Explore = ({ userEvent, setIndex }) => {
  const [queryString, setQueryString] = useState(null);
  const { data, isLoading, isError } = useExplore(queryString);
  const [isToggle, toggle] = useToggle();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const saveImageToSlide = (image) => {
    toggle();
    setTimeout(() => {
      userEvent({ Url: image.raw });
      toggle();

      // when user have selected an image, return to upload view.
      setIndex(0);
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setQueryString(inputRef.current.value.trim());
  };

  return (
    <>
      <form className="py-3 explore-form" onSubmit={handleSubmit}>
        <input id="explore" ref={inputRef} type="text" />
        <FontAwesomeIcon className="icon" icon={faSearch} size="lg" />
      </form>

      <div className="gallery-wrapper">
        {isError && <ErrorMessage>Something went wrong</ErrorMessage>}

        {isLoading && <Loader />}

        {data && data.length < 1 && (
          <p className="py-3">Nothing matched your search</p>
        )}

        {isToggle ? (
          <Loader />
        ) : (
          data &&
          data.urls.map((item, i) => {
            return (
              <GalleryCard
                key={i}
                item={item}
                saveImageToSlide={saveImageToSlide}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default Explore;
