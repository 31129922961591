export const replaceSpecialChars = (str) => {
    let stringWithNoSpecialChars = "";
  
    for (let i = 0; i < str.length; i++) {
      if (str[i] === "å" || str[i] === "ä" || str[i] === "Å" || str[i] === "Ä") {
        stringWithNoSpecialChars += "a";
      } else if (str[i] === "ö" || str[i] === "Ö") {
        stringWithNoSpecialChars += "o";
      } else {
        stringWithNoSpecialChars += str[i];
      }
    }
  
    return stringWithNoSpecialChars.toLocaleLowerCase();
  };