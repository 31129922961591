import { IdTokenEntity } from "@azure/msal-common";
import Content from "../../components/slides/Content";
import Events from "../../components/slides/Events";
import FreeRooms from "../../components/slides/FreeRooms";
import ImageOnly from "../../components/slides/ImageOnly";

export const output = (item, key) => {
    switch (item.Type) {
        case "content":
            return <Content slide={item} key={key} />;
        case "events":
            return <Events location={item.Location} color={item.Color} key={key} />;
        case "freeRooms":
            return <FreeRooms location={item.Location} color={item.Color} key={key} />;
        case "iframe":
            return <iframe src={item.Website} key={key} />;
        default:
            return <ImageOnly slide={item} key={key} />;
    }
};
