import { prefix } from "../../services/defaults";
const types = ["header", "paragraph", "list"];

const Content = ({ slide }) => {

  const loadContent = ({ blocks }) => {
    return blocks.map((item, i) => {
      const { level } = item.data;
      switch (item.type) {
        case types[0]:
          if (level === 2) {
            return <h2 key={i} dangerouslySetInnerHTML={{__html: item.data.text}}></h2>;
          } else if (level === 3) {
            return <h3 key={i} dangerouslySetInnerHTML={{__html: item.data.text}}></h3>;
          } else {
            return <h4 key={i} dangerouslySetInnerHTML={{__html: item.data.text}}></h4>;
          }
        case types[1]:
          return <p key={i} dangerouslySetInnerHTML={{__html: item.data.text}}></p>;
        case types[2]:
          const { style } = item.data;
          if (style === "ordered") {
            return (
              <ol key={i}>
                {item.data.items.map((listItem, j) => {
                  return <li key={j} dangerouslySetInnerHTML={{__html: listItem}}></li>;
                })}
              </ol>
            );
          } else {
            return (
              <ul key={i}>
                {item.data.items.map((listItem, j) => {
                  return <li key={j} dangerouslySetInnerHTML={{__html: listItem}}></li>;
                })}
              </ul>
            );
          }

        default:
          return null;
      }
    });
  };

  return (
    <div className="slide-with-content" style={{ backgroundColor: slide.Color }}>
      <div
        className="image background-image"
        style={{ backgroundImage: slide.Url.includes('unsplash') ? `url(${slide.Url})` : `url(${prefix}${slide.Url})` }}
      ></div>
      <div className="content">{loadContent(slide.Text)}</div>
    </div>
  );
};

export default Content;
