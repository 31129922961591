import axios from "axios"
import { base } from './defaults'

axios.defaults.baseURL = base;


export const get = async (endpoint) => {
  const res = await axios.get(endpoint);

  return res.data;
};


export const changeSlidesOrder = async (newOrder = null) =>{
  if(!newOrder) return;

  const data = newOrder

  const res = await axios.put("/Slideshow/order", data);
  return res;
}

export const getAllSlideShows = async () => {
  const res = await get("slideshows");

  return res;
};

export const getSlideShowById = async (id) => {
  const res = await get(`slideshow/${id}`);



  const parsedValue = res.Slides.map((item) => {
    if (item.Text) {
      return { ...item, Text: JSON.parse(item.Text), Type: 'content' };
    } else {
      return item;
    }
  });

  // check if res has resturned something bigger than 0
  return parsedValue.length > 0
    ? { ...res, Slides: parsedValue }
    : { msg: "nothing here" };
};

export const getSlideByCode = async (code = null) => {
  if (!code) return;
  const res = await get(`/code?codeId=${code}`);
  return res;
};

export const createSlideshowId = async (param = null) => {
  if (!param) return;

  const data = {
    title: param.title,
    description: param.desc,
    author: param.author,
    Timer: 20 // default value for timer
  };

  const res = await axios.post("/Slideshow", data);

  return res.data;
};


export const updateSlideshowTimer = async (data) => {

  const res = await axios.put("/Slideshow", data)

  return res


}




export const deleteSlideShowById = async (id = null) => {
  if (!id) return;

  // maby need to return some kind of response?
  await axios.delete(`Slideshow/${id}`);
};
