import { useMutation, useQueryClient } from "react-query";
import { deleteUserById } from "../services/VisiterApi";

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUserById, {
    onSuccess: () => {
      queryClient.invalidateQueries("visitors");
    },
  });
};

export default useDeleteUser;
