import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";

// login
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Navigation = () => {
  const { pathname } = useLocation();

  const [isOpen, setToggle] = useState(false);

  const settingsRef = useRef();

  const { instance } = useMsal();

  const handleLogin = async () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  const handleLogout = async () => {
    instance.logoutRedirect(loginRequest); // loginRequest contains the logout redirect uri as well
  };

  useEffect(() => {
    document.addEventListener("mousedown", toggleMenu);

    return () => {
      document.removeEventListener("mousedown", toggleMenu);
    };
  }, []);

  // only active on homepage where there is a dropdown available
  const toggleMenu = (e) => {
    if (settingsRef.current?.contains(e.target)) {
      return;
    }

    setToggle(false);
  };

  const loadContent = () => {
    if (pathname.includes("login")) {
      return (
        <>
          <h3 className="text-white px-4">CI 1.0</h3>
          <NavLink to="/" className="nav-link text-white logout">
            Use code
          </NavLink>
        </>
      );
    } else if (pathname === "/") {
      return (
        <>
          <h3 className="text-white px-4">CI 1.0</h3>
          {
            <a
              className="nav-link text-white logout clickable"
              onClick={handleLogin}
            >
              Login
            </a>
          }
        </>
      );
    } else {
      return (
        <>
          <NavLink to="/slideshows" className="nav-link text-white px-4">
            <h3>CI 1.0</h3>
          </NavLink>

          <div className="items">
            <NavLink to="/create" className="nav-link text-white">
              Create Slideshow
            </NavLink>
            <NavLink to="/settings" className="nav-link text-white logout">
              Settings
            </NavLink>
            <a
              className="nav-link text-white logout clickable"
              onClick={handleLogout}
            >
              Logout
            </a>
          </div>

          <div ref={settingsRef} className="hamburger">
            <FontAwesomeIcon
              className="icon"
              icon={faBars}
              size="2x"
              onClick={() => setToggle(!isOpen)}
            />
            <div className={isOpen ? "hamburgerOpen" : "hamburgerClosed"}>
              {isOpen && (
                <>
                  <NavLink to="/create" className="nav-link text-white">
                    Create Slideshow
                  </NavLink>
                  <NavLink
                    to="/settings"
                    className="nav-link text-white logout"
                  >
                    Settings
                  </NavLink>
                  <NavLink to="/login" className="nav-link text-white logout">
                    Logout
                  </NavLink>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="nav-wrapper">
      <div className="navigation">{loadContent()}</div>
    </div>
  );
};

export default Navigation;
