// helpers
import { settings_navigation } from "../utils/config_tab";

// components
import AddKnownNamesForm from "../components/forms/AddKnownNamesForm";
import Container from "../components/layout/Container";
import Navigation from "./partials/Navigation";
import Profile from "../components/settings/Profile";
import Tab from "./partials/Tab";
import Timers from "../components/settings/Timers";

// hooks
import useTab from "../hooks/useTab";
import PersonsList from "../components/settings/PersonsList";

const SettingsPage = () => {
  const [tabIndex, setIndex] = useTab(0);

  return (
    <>
      <Navigation />
      <Container>
        <div className="settings-wrapper">
          <div className="settings-container">
            <Tab
              tabIndex={tabIndex}
              setIndex={setIndex}
              tabs={settings_navigation}
            />

            <div className="component-wrapper">
              {tabIndex === -1 && <Profile />}
              {tabIndex === 0 && (
                <>
                  <AddKnownNamesForm />
                  <PersonsList />
                </>
              )}
              {tabIndex === 1 && <Timers />}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SettingsPage;
