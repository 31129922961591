import { prefix, staticPrefix } from "../../services/defaults";
import { useState } from "react";
import { useHistory } from "react-router";

// css
import "../../scss/dropdown.scss";

//components
import Button from "../forms/Button";
import Description from "./Description";
import CardSettings from "./CardSettings";

const SlideShowCard = ({
  index,
  whenDragged,
  dragStart,
  dragEnd,
  cardEventHandlerTwo,
  cardEventHandlerOne,
  data,
  deleteById,
  page,
}) => {

  const history = useHistory();
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [opacity, setOpacity] = useState(1);


  const handleDropdownToggle = (id) => {
    if (page === "Play") {
      setDropdownToggle(!dropdownToggle);
    } else {
      cardEventHandlerTwo(id);
    }
  };

  const editSlideShow = (id) => {
    history.push(`/edit/${id}`);
  };

  const deleteSlideShow = (id) => {
    deleteById(id);
    setDropdownToggle(!dropdownToggle);
  };

  const renderBackgroundImage = ({ PreviewImage, Url, Location, Website }) => {
    // used for cards on homepage

    if (PreviewImage === "meeting.jpg" || PreviewImage === "website.jpg") {
      return `url(${staticPrefix}${PreviewImage})`;
    }

    if (PreviewImage) {
      if (PreviewImage.includes("unsplash")) return `url(${PreviewImage})`;
      return `url(${prefix}${PreviewImage})`;
    }

    // used for cards on slideshowpage
    if (Url) {
      if (Url.includes("unsplash")) return `url(${Url})`;
      return `url(${prefix}${Url})`;
    }

    if (Location) {
      return `url(${staticPrefix}${"meeting.jpg"})`;
    }

    if (Website) {
      return `url(${staticPrefix}${"website.jpg"})`;
    }


    //when no images exist a colored background will be shown in the card
    return null;
  };

  const handleDragStart = (e) =>{
    setOpacity(0.3);
    dragStart(e);
  }

  const handleDragEnd = (e) =>{
    setOpacity(1.0);
    dragEnd(e);
  }

  const test = (e)=>{
    //console.log(e.screenX, e.screenY)
    //console.log(e.pageX, e.pageY)
    whenDragged(e, index)
  }


  return (
    <div draggable="true"
      // onDragStart={handleDragStart}
      // onDragEnd={handleDragEnd}
      // onDrag={test}
      className="slideShowCard background-image"
      style={{
        backgroundImage: renderBackgroundImage(data), opacity: opacity
      }}
    >
      <CardSettings
        data={data.Id}
        page={page}
        edit={editSlideShow}
        deleteSlideShow={deleteSlideShow}
        toggle={handleDropdownToggle}
        isToggle={dropdownToggle}
        setToggle={setDropdownToggle}
      />

      <div className="content">
        <Description data={data} page={page} />
        <Button opacity={0.3} page={page} data={data.Id} event={cardEventHandlerOne} />
      </div>
    </div>
  );
};

export default SlideShowCard;
