import { attendees } from "../../utils/mockData";
import { parseEmail } from "../../utils/helpers/parse_email";
import useGetMeetings from "../../hooks/useGetMeetings";

const Events = ({ location, color }) => {
  const { data, isError, isLoading } = useGetMeetings(location);
  return (
    <div className="bookingsWrapper" style={{ backgroundColor: color }}>
      <div className="info">
        <h1>Välkommen till Consid!</h1>
        <h4>Slå dig gärna ner i loungen medan du väntar</h4>
        {data && data.length === 0 && (
          <img src="/consid_logo.png" alt="consid logo" />
        )}
        <ul>
          {data?.map((item, i) => {
            const result = parseEmail(item.Email);
            return (
              <li
                key={i}
                style={{ fontSize: data?.length > 10 ? "1.5em" : "2em" }}
              >
                {result && `${item.Text ?? result} - ${item.Room} ${item.Time}`}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Events;
