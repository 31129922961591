import { useMutation, useQueryClient } from "react-query";
import { deleteSlideShowById } from "../services/SlideShowsApi";

const useDeleteSlideshow = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSlideShowById, {
    onSuccess: () => {
      queryClient.invalidateQueries(["slideshows"]);
    },
  });
};

export default useDeleteSlideshow;
