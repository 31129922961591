const Tab = ({ tabIndex, setIndex, tabs }) => {
  return (
    <div className="tab">
      {tabs.map((tab, i) =>
        i === tabIndex ? (
          <p key={i} onClick={() => setIndex(i)} className="active">
            {tab.name}
          </p>
        ) : (
          <p key={i} onClick={() => setIndex(i)}>
            {tab.name}
          </p>
        )
      )}
    </div>
  );
};

export default Tab;
