import { useState } from "react";
import { useHistory } from "react-router";

//layout
import FormContainer from "../components/layout/FormContainer";

// components
import ErrorMessage from "../components/userfeedback/ErrorMessage";
import UserForm from "../components/forms/UserForm";
import Navigation from "./partials/Navigation";

const correctPassword = "1212";
const LoginPage = () => {
  const [feedBackMessage, setFeedBackMessage] = useState(false);
  const history = useHistory();

  const handleSubmit = (password) => {
    if (password === correctPassword) {
      history.push("/slideshows");
    } else {
      setFeedBackMessage(true);
    }
  };

  return (
    <>
      <Navigation />
      <div className="loginPageWrapper">
        <h2 className="text-center my-4">Consids Infotainmentsystem</h2>
        {feedBackMessage && <ErrorMessage>Wrong password</ErrorMessage>}
        <FormContainer>
          <UserForm
            feedBackMessage={feedBackMessage}
            handleUserEvent={handleSubmit}
            page={{ currentPage: "Login", action: "Login" }}
          />
        </FormContainer>
      </div>
    </>
  );
};

export default LoginPage;
