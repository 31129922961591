import { useQuery } from "react-query";
import { getFreeRooms } from "../services/MeetingsApi";

const useGetFreeRooms = (location) => {
  return useQuery("rooms", () => getFreeRooms(location), {
    refetchInterval: 60000 * 1,
  });
};

export default useGetFreeRooms;
