import { useMutation, useQueryClient } from "react-query";
import { deleteSlideById } from "../services/SlideApi";

const useDeleteslide = (id) => {
    const queryClient = useQueryClient();
    return useMutation(deleteSlideById, {
        onSuccess: () => {
            queryClient.invalidateQueries(["slideshow", id]);
        }
    })
}
 
export default useDeleteslide;