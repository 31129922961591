import { prefix } from "../../services/defaults";

const ImageOnly = ({ slide }) => {
  const isWide = () => {
    const imgSrc = slide.Url.includes("unsplash")
      ? `${slide.Url}`
      : `${prefix}${slide.Url}`;
    const img = new Image();
    img.src = imgSrc;
    if (img.width / img.height === 4 / 3) {
      return true;
    } else {
      return true;//false; // set to false if vertical images should cover screen area
    }
  };

  return (
    <div
      className="image background-image"
      style={{
        backgroundColor: slide.Color,
        backgroundImage: slide.Url.includes("unsplash")
          ? `url(${slide.Url})`
          : `url(${prefix}${slide.Url})`,
        backgroundSize: isWide() ? "contain" : "cover",
      }}
    ></div>
  );
};

export default ImageOnly;
