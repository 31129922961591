const isDateValid = (slide) => {
    const currentDate = new Date().getTime();
    if (
      currentDate > Date.parse(slide.StartDate) &&
      currentDate < Date.parse(slide.EndDate)
    ) {
      return true;
    }
    return false;
  };
  
  export const increment = (currentIndex, slides) => {
    let nextIndex = currentIndex + 1;
  
    for (nextIndex; nextIndex <= slides.length + 1; nextIndex++) {
      if (nextIndex >= slides.length) {
        nextIndex = 0;
      }
  
      if (slides[nextIndex].StartDate === null) {
        return nextIndex;
      }
      if (isDateValid(slides[nextIndex])) {
        return nextIndex;
      }
    }
    return 0;
  };