import { useEffect, useState } from "react";
import { increment } from "../../utils/helpers/date_checker";
import { output } from "../../utils/helpers/component_output";
import { useQueryClient } from "react-query";

const SlidesContainer = ({ response, timer }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (currentIndex === -1) {
      incrementIndex();
      return;
    }
    const timeOutId = setTimeout(incrementIndex, timer * 1000); //seconds

    return () => {
      clearTimeout(timeOutId);
    };
  }, [currentIndex]);

  const incrementIndex = () => {
    let index = increment(currentIndex, response);
    queryClient.invalidateQueries("meetings");
    setCurrentIndex(index);
  };

  const loadContent = (slides) => {
    return slides.map((item, key) => {
      let outputToRender;
      if (key === currentIndex) {
        outputToRender = output(item, key);
        return outputToRender;
      } else {
        outputToRender = output(item);
        return (
          <div className="hide" key={key}>
            {outputToRender}
          </div>
        );
      }
    });
  };

  return (
    <div className="slideshow">
      {currentIndex === -1 && (
        <h1 className="text-center my-3 text-white">Loading ...</h1>
      )}
      <div className="carousel">{loadContent(response)}</div>
    </div>
  );
};

export default SlidesContainer;
