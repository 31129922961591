// tools.js
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Header from "@editorjs/header";

export const EDITOR_JS_TOOLS = {
  paragraph: {
    Paragraph,
    inlineToolbar: ['bold', 'italic'],
  },
  list: { 
    inlineToolbar: ['bold', 'italic'],
    class: List
  },
  header: {
    inlineToolbar: ['bold', 'italic'],
    class: Header,
      config: {
          levels: [2,3,4],
          defaultLevel: 2
      }
  },
};
