//hooks
import useDeleteUser from "../../hooks/useDeleteUse";
import useKnownVisitors from "../../hooks/useKnownVisitors";

//components
import Loader from "../userfeedback/Loader";
import ErrorMessage from "../userfeedback/ErrorMessage";

//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";


const PersonsList = () => {
  const { data, isLoading, isError } = useKnownVisitors();

  const { mutate: deleteUser } = useDeleteUser();

  const handleClick = (id) => {
    deleteUser(id);
  };

  return (
    <div className="persons-list">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Company</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{++i}</th>
                  <td>{item.FirstName}</td>
                  <td>{item.LastName}</td>
                  <td>{item.Company}</td>
                  <td onClick={() => handleClick(item.Id)}>
                    <button>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faTrash}
                        size="lg"
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {isLoading && <Loader />}
      {isError && <ErrorMessage>Something went wrong</ErrorMessage>}
    </div>
  );
};

export default PersonsList;
