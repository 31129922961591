import axios from "axios";
import { base } from "./defaults";

axios.defaults.baseURL = base;

export const get = async (endpoint) => {
  const res = await axios.get(endpoint);

  return res.data;
};

// Slide
export const getSlideById = async (id) => {
  if (!id) return;

  const res = await get(`Slide/${id}`);

  if (res.Text) {
    return { ...res, Text: JSON.parse(res.Text) };
  } else {
    return res;
  }
};

export const uploadSlide = async ({
  startDate,
  endDate,
  imageToUpload,
  id,
  type,
  richText,
  location,
  website,
  color,
}) => {
  if (!imageToUpload) return;
  const formData = new FormData();

  formData.append("SlideshowId", id);

  // will always have a value
  formData.append("file", imageToUpload);

  //parse starddate and append it to formData
  if (startDate) {
    const startTime = startDate.toISOString();
    formData.append("StartDate", startTime);
  }

  //parse enddate and append it to formdata
  if (endDate) {
    const endTime = endDate && endDate.toISOString();
    formData.append("EndDate", endTime);
  }

  if (richText && richText.blocks.length > 0) {
    const jsonValue = JSON.stringify(richText).replaceAll("&nbsp;", "");

    formData.append("Text", jsonValue);
  }
  if (location !== undefined) {
    formData.append("Location", location);
    formData.append("Type", type);
  }
  if (website !== undefined) {
    formData.append("Website", website);
    formData.append("Type", type);
  }
  formData.append("Color", color);
  //send formdata to backend
  const res = await axios.post("Image", formData);

  return res;
};

export const createBookingSlideById = ({ id }) => {
  console.log({ id });
  return "sucessfull";
};

export const deleteSlideById = async (id = null) => {
  if (!id) return;
  // maby need to return some kind of respons
  await axios.delete(`Slide/${id}`);
};

export const updateSlideById = async ({
  imageToUpdate,
  slideId,
  richText,
  type,
  startDate,
  endDate,
  location,
  website,
  color,
}) => {
  const formData = new FormData();
  if (imageToUpdate) {
    formData.append("file", imageToUpdate);
  }

  if (richText) {
    if (richText.blocks.length > 0) {
      const jsonValue = JSON.stringify(richText);
      formData.append("Text", jsonValue);
    }
  }

  if (startDate) {
    const startTime = startDate.toISOString();
    formData.append("StartDate", startTime);
  }

  if (endDate) {
    const endTime = endDate && endDate.toISOString();
    formData.append("EndDate", endTime);
  }

  formData.append("slideId", slideId);
  if (location !== undefined) {
    formData.append("Location", location);
    formData.append("Type", type);
  }
  if (website !== undefined) {
    formData.append("Website", website);
    formData.append("Type", type);
  }
  formData.append("Color", color);

  const res = await axios.put(`Image`, formData);

  return res;
};

// endpoint for images
export const getAllImages = async () => {
  const res = await get("/Images");

  return res;
};

export const getImages = async (query) => {
  if (!query) return;

  const res = await axios.get(`Unsplash?page=1&per_page=12&query=${query}`);

  //const urls = res.data.results.map((item) => {
  //  return item.urls;
  //});

  return res.data;
};
