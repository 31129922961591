import { useState, useEffect, useRef } from "react";
import FormButton from "./FormButton";
import Loader from "../userfeedback/Loader";

const LoginForm = ({ handleUserEvent, isLoading, page }) => {

  const [pwInput, setPwInput] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });

  const [password, setPassword] = useState("");

  //hooks
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  // this sideffect is triggered when user change any of the inputfield. Is used to build the password
  useEffect(() => {
    setPassword(
      pwInput.input1 + pwInput.input2 + pwInput.input3 + pwInput.input4
    );
  }, [pwInput]);

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    if (value.length >= maxLength) {
      //check against total amount of field
      if (parseInt(fieldIndex) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=input-${parseInt(fieldIndex) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    setPwInput({
      ...pwInput,
      [`input${fieldIndex}`]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleUserEvent(password);
  };

  return (
    <form className="login-form-wrapper" onSubmit={handleSubmit}>      
      <h1>{page.currentPage}</h1>
      <div className="input-type-number-wrapper">
        <input
          ref={inputRef}
          name="input-1"
          className="input"
          min="0"
          max="9"
          type="number"
          id="loginInput"
          maxLength="1"
          onChange={(e) => handleChange(e)}
          value={pwInput.input1}
        />
        <input
          className="input"
          name="input-2"
          min="0"
          max="9"
          type="number"
          id="loginInput"
          maxLength="1"
          onChange={(e) => handleChange(e)}
          value={pwInput.input2}
        />
        <input
          className="input"
          name="input-3"
          min="0"
          max="9"
          type="number"
          id="loginInput"
          maxLength="1"
          onChange={(e) => handleChange(e)}
          value={pwInput.input3}
        />
        <input
          className="input"
          name="input-4"
          min="0"
          max="9"
          type="number"
          maxLength="1"
          onChange={(e) => handleChange(e)}
          value={pwInput.input4}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <FormButton
          value={page.action}
          userEvent={handleSubmit}
          disable={password.length !== 4}
        />
      )}
    </form>
  );
};

export default LoginForm;
