import { useState, useEffect } from "react";
import { prefix } from "../../services/defaults";

const PreviewImage = ({ file }) => {
  const [prevImage, setPrevImage] = useState(null);

  useEffect(() => {
    let image;
    if (file.length === undefined) {
      if (file.hasOwnProperty("Url")) {
        if (file.Url?.includes("unsplash")) {
          setPrevImage(file.Url);
          return;
        }
        setPrevImage(prefix + file.Url);
      } else {
        let images = [];
        images.push(URL.createObjectURL(file));
        setPrevImage(images);
      }
    } else {
      let images = [];
      for (let i = 0; i < file.length; i++) {
        images.push(URL.createObjectURL(file[i]));
      }
      setPrevImage(images);
    }

    return () => {
      URL.revokeObjectURL(image);
      setPrevImage(null);
    };
  }, [file]);

  return (
    <>
      <div className={(prevImage?.length > 1 && typeof prevImage !== 'string') ? "imagesPreviewContainer" : ""}>
        {prevImage && Array.isArray(prevImage) ? (
          prevImage.map((image, i) => {
            return (
              <div
                key={i}
                className="preview background-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            );
          })) : (
          <div
            className="preview background-image"
            style={{ backgroundImage: `url(${prevImage})` }}
          ></div>
        )}
      </div>
    </>
  );
};

export default PreviewImage;
