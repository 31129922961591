import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ControlPanel = ({ slideId, id }) => {
  const history = useHistory();

  return (
    <div className="user-control-panel">
      {slideId ? (
        <p>
          Editing slide: <span style={{ fontWeight: "bolder" }}>{slideId}</span>
        </p>
      ) : (
        <p>
          Adding slide to slideshow:
          <span style={{ fontWeight: "bolder" }}> {id}</span>
        </p>
      )}

      <span style={{cursor: 'pointer'}} onClick={() => history.push(`/edit/${id}`)}>
        <FontAwesomeIcon className="icon" icon={faArrowCircleLeft} size="2x" />
      </span>
    </div>
  );
};

export default ControlPanel;

ControlPanel.defaultProps = {
  slideId: null,
};
