import Navigation from "./partials/Navigation";
import SlideShowForm from "../components/forms/SlideShowForm";
import useCreateSlideshow from "../hooks/useCreateSlideshow";
import ErrorMessage from "../components/userfeedback/ErrorMessage";

import { Spinner } from "react-bootstrap";

const CreateSlideShowPage = () => {
  const { mutate: create, isLoading, isError } = useCreateSlideshow();

  const createSlideshow = (param) => {
    create(param);
  };

  return (
    <>
      <Navigation />
      <div className="createSlideshow">
        {isError && <ErrorMessage width={"80%"}>Ohno 😲</ErrorMessage>}
        <SlideShowForm createSlideshow={createSlideshow} />

        {isLoading && (
          <div className="text-center py-2">
            <Spinner animation="grow" />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateSlideShowPage;
