import { useMutation, useQueryClient} from "react-query";
import { createKnownVisiter } from "../services/VisiterApi";

const useCreateKnownVisiter = () => {
        const queryClient = useQueryClient()
    
    return useMutation(createKnownVisiter, {
        onSuccess: () => {
            queryClient.invalidateQueries("visitors")
        }
    })
}
 
export default useCreateKnownVisiter;