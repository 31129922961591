import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faTrash } from "@fortawesome/free-solid-svg-icons";

const CardSettings = ({
  data,
  page,
  edit,
  deleteSlideShow,
  isToggle,
  setToggle,
  toggle,
}) => {
  const settingsRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", toggleMenu);

    return () => {
      document.removeEventListener("mousedown", toggleMenu);
    };
  }, []);

  // only active on homepage where there is a dropdown available
  const toggleMenu = (e) => {
    if (settingsRef.current.contains(e.target)) {
      return;
    }
    setToggle(false);
  };

  const loadButton = () => {
    if (page !== "Play") {
      return (
        <button onClick={() => toggle(data)} className="edit">
          <FontAwesomeIcon className="icon" icon={faTrash} size="lg" />
        </button>
      );
    }

    return (
      <button onClick={() => toggle(data)} className="edit">
        <FontAwesomeIcon className="icon" icon={faEllipsisV} size="lg" />
      </button>
    );
  };

  return (
    <div ref={settingsRef} className="dropdownContainer">
      {page !== "Save" && loadButton()}
      {isToggle && (
        <div className="dropdown">
          <ul>
            <li onClick={() => edit(data)}>Edit</li>
            <li onClick={() => deleteSlideShow(data)}>Remove</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default CardSettings;
