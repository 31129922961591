import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import { add_slide_navigation } from "../utils/config_tab";

//components
import Calendar from "../components/create/DatePicker";
import Container from "../components/layout/Container";
import ControlPanel from "../components/ControlPanel";
import ErrorMessage from "../components/userfeedback/ErrorMessage";
import Explore from "../components/create/Explore";
import FileUploader from "../components/create/FileUploader";
import History from "../components/create/History";
import Navigation from "./partials/Navigation";
import PreviewImage from "../components/create/PreviewImage";
import Tab from "./partials/Tab";
import TextEditor from "../components/create/TextEditor";
import UploadButton from "../components/create/UploadButton";
import UserFeedback from "../components/userfeedback/UserFeedback";
import ColorPicker from "../components/create/ColorPicker";

// hooks
import useSlide from "../hooks/useSlide";
import useTab from "../hooks/useTab";
import useCreateSlide from "../hooks/useCreateSlide";
import useUpdateSlide from "../hooks/useUpdateSlide";
import ExternalMeetingForm from "../components/create/ExternalMeetingForm";
import ExternalWebpage from "../components/create/ExternalWebpage";

const AddSlidePage = () => {
  // slideId will be undefiend in create mode
  const { id, slideId } = useParams();

  const [tabIndex, setIndex] = useTab(0);
  const queryClient = useQueryClient();

  // state for uploading slides
  const [file, setFile] = useState(null);
  const [feedBackMessage, setFeedBackMessage] = useState(null);
  const [text, setText] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState(null);
  const [website, setWebsite] = useState(null);
  const [color, setColor] = useState("");
  const [buttonEnabled, setButtonEnabledState] = useState(false);
  const [extraTextCheck, setextraTextCheck] = useState(false); // workaround for the text editor
  const [displayFreeMeetingRooms, setDisplayFreeMeetingRooms] = useState("");
  const [locationSelected, setLocationSelected] = useState(false);

  // states for userfeedback
  const [successOnUpload, setSuccessOnUpload] = useState(false);
  const [successOnUpdate, setSuccessOnUpdate] = useState(false);
  const [errorOnUpload, setErrorOnUpload] = useState(false);

  // read slide
  const {
    data,
    isSuccess: isSuccessOnRead,
    isError: isErrorOnRead,
  } = useSlide(slideId);

  // upload slide
  const { mutate: addSlide, isLoading: isLoadingOnCreate } = useCreateSlide();

  // update slide
  const {
    mutate: updateSlide,

    isLoading: isLoadingOnUpdate,
  } = useUpdateSlide();

  const radioButtonUpdate = (e) => {
    console.log(e)
    setDisplayFreeMeetingRooms(e);
    if(!locationSelected) return;
    setButtonEnabledState(true);
  }

  const setLocationUpdate = (value) => {
    setLocationSelected(value);
    if(displayFreeMeetingRooms == "") return;
    setButtonEnabledState(true);
  }

  useEffect(() => {
    if (isSuccessOnRead && data) {
      setIsEditing(true);
      setFile({ Url: data.Url });
      setColor(data.Color);
      setText(data.Text);
      setextraTextCheck(true);
    }
  }, [isSuccessOnRead]);

  useEffect(() => {
    let timeoutId;

    if (feedBackMessage) {
      timeoutId = setTimeout(() => setFeedBackMessage(null), 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [feedBackMessage]);

  useEffect(() => {
    // tab switching logic
    if(tabIndex == 0 && file !== null){
      setButtonEnabledState(true);
      return;
    };

    // always disable button when switching and clear some inputs
    setButtonEnabledState(false);
    setWebsite(null);
    setLocation(null);
  }, [tabIndex]);

  useEffect(() => {
    if(file !== null){
      setButtonEnabledState(true);
    }
  }, [file]);

  useEffect(() => {
    if(website?.length > 7){ // Todo: Change to regex check of website?
      setButtonEnabledState(true);
    }
  }, [website]);


  const resetValues = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const singleUpload = async (file) => {
    // modify to accept multiple files
    let imageToUpload;
    if (file.hasOwnProperty("Url")) {
      imageToUpload = file.Url;
    } else {
      imageToUpload = file;
    }

    const values = {
      startDate,
      endDate,
      imageToUpload,
      id,
      richText: text,
      color,
    };

    addSlide(values, {
      onSuccess: () => {
        setSuccessOnUpload(true);
        setTimeout(() => setSuccessOnUpload(false), 3000);

        // clear filestate when image is uploaded.
        setFile(null);
        queryClient.invalidateQueries("slideshow", id);
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };

  const multiUpload = async (files) => {
    for (let i = 0; i < files.length; i++) {
      const values = {
        startDate,
        endDate,
        imageToUpload: files[i],
        id,
        richText: text,
        color,
      };

      addSlide(values, {
        onSuccess: () => {
          setSuccessOnUpload(true);
          setTimeout(() => setSuccessOnUpload(false), 3000);

          // clear filestate when image is uploaded.
          setFile(null);
          queryClient.invalidateQueries("slideshow", id);
        },
        onError: () => {
          setErrorOnUpload(true);
          setTimeout(() => setErrorOnUpload(false), 3000);
        },
      });
    }
  };
  const handleUpload = async () => {
    if(location){
      handleMeetingUpload();
      return;
    }

    if(website){
      handleUrlUpload();
      return;
    }

    if (file.length === undefined) {
      singleUpload(file);
    } else {
      multiUpload(file);
    }
  };

  const handleUpdate = async () => {
    if(location){
      handleMeetingUpdate();
      return;
    }
    if(website){
      handleUrlUpdate();
      return;
    }

    let imageToUpdate;
    if (file.hasOwnProperty("Url")) {
      imageToUpdate = file.Url;
    } else {
      imageToUpdate = file;
    }

    const values = {
      startDate,
      endDate,
      imageToUpdate,
      slideId,
      richText: text,
      color,
    };

    updateSlide(values, {
      onSuccess: () => {
        setSuccessOnUpdate(true);
        setTimeout(() => setSuccessOnUpdate(false), 3000);

        queryClient.invalidateQueries("slideshow", id);
        queryClient.invalidateQueries("slide", slideId);
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };

  const handleMeetingUpdate = async () => {
    const props = {
      startDate,
      endDate,
      imageToUpdate: "event",
      type: displayFreeMeetingRooms == 1 ? "freeRooms" : "events",
      slideId,
      richText: null,
      location: location,
      color,
    };

    updateSlide(props, {
      onSuccess: () => {
        setSuccessOnUpload(true);
        setTimeout(() => setSuccessOnUpload(false), 3000);

        // clear filestate when image is uploaded.
        setFile(null);
        queryClient.invalidateQueries("slideshow", id);
        queryClient.invalidateQueries("meetings");
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };

  const handleMeetingUpload = async () => {
    const props = {
      startDate,
      endDate,
      imageToUpload: "event",
      type: displayFreeMeetingRooms == 1? "freeRooms" : "events",
      id,
      richText: null,
      location: location,
      color,
    };

    addSlide(props, {
      onSuccess: () => {
        setSuccessOnUpload(true);
        setTimeout(() => setSuccessOnUpload(false), 3000);

        // clear filestate when image is uploaded.
        setFile(null);
        queryClient.invalidateQueries("slideshow", id);
        queryClient.invalidateQueries("meetings");
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };

  const handleUrlUpload = async () => {
    const props = {
      startDate,
      endDate,
      imageToUpload: "website",
      type: "iframe",
      id,
      richText: null,
      website: website,
      color,
    };

    addSlide(props, {
      onSuccess: () => {
        setSuccessOnUpload(true);
        setTimeout(() => setSuccessOnUpload(false), 3000);

        // clear filestate when image is uploaded.
        setFile(null);
        queryClient.invalidateQueries("slideshow", id);
        queryClient.invalidateQueries("meetings");
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };
  const handleUrlUpdate = async () => {
    const props = {
      startDate,
      endDate,
      imageToUpdate: "website",
      type: "iframe",
      slideId,
      richText: null,
      website: website,
      color,
    };

    updateSlide(props, {
      onSuccess: () => {
        setSuccessOnUpload(true);
        setTimeout(() => setSuccessOnUpload(false), 3000);

        // clear filestate when image is uploaded.
        setFile(null);
        queryClient.invalidateQueries("slideshow", id);
        queryClient.invalidateQueries("meetings");
      },
      onError: () => {
        setErrorOnUpload(true);
        setTimeout(() => setErrorOnUpload(false), 3000);
      },
    });
  };

  return (
    <>
      <Navigation />
      <Container>
        <UserFeedback
          isSuccess={successOnUpload ? successOnUpload : successOnUpdate}
          isLoading={isLoadingOnCreate ? isLoadingOnCreate : isLoadingOnUpdate}
        />

        {isErrorOnRead && <ErrorMessage>Something went wrong :(</ErrorMessage>}
        {errorOnUpload && <ErrorMessage>Something went wrong :(</ErrorMessage>}
        <div>
          <ControlPanel id={id} />
          <Tab
            tabIndex={tabIndex}
            setIndex={setIndex}
            tabs={add_slide_navigation}
          />
        </div>
        <div className="controls">
          <div className="item-one">
            <div>
              {tabIndex === 0 && (
                <>
                  <FileUploader setFile={setFile} isEditing={isEditing} />
                  <Calendar
                    endDate={endDate}
                    setEndDate={setEndDate}
                    resetValues={resetValues}
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />

                  <ColorPicker
                    color={color}
                    setColor={setColor}
                  />

                  {file && <PreviewImage file={file} />}
                </>
              )}

              {tabIndex === 1 && (
                <History
                  userEvent={setFile}
                  setIndex={setIndex}
                  fileNameFilter={data?.Url}
                />
              )}
              {tabIndex === 2 && (
                <Explore userEvent={setFile} setIndex={setIndex} />
              )}

              {tabIndex === 3 && (
                <div className="meetings-container">
                <ExternalMeetingForm
                  setLocation={setLocation}
                  userEvent={setLocationUpdate}
                  />
                  <ColorPicker
                    color={color}
                    setColor={setColor}
                  />
                  <div className="radio-buttons" onChange={e => radioButtonUpdate(e.target.value)}>
                    <label>
                      <input type='radio' value={1} checked={displayFreeMeetingRooms == '1'}></input>
                      Display available rooms
                    </label>
                    <label>
                      <input type='radio' value={2} checked={displayFreeMeetingRooms == '2'} ></input>
                      Display booked rooms for guests
                    </label>
                  </div>
                </div>
              )}

              {tabIndex === 4 && (
                <ExternalWebpage
                  setWebsite={setWebsite}
                  userEvent={isEditing ? handleUrlUpdate : handleUrlUpload}
                />
              )}

              {/* button visible on big screens */}
            </div>
            {tabIndex !== 1 && (
              <UploadButton
                enabled={buttonEnabled}
                userEvent={isEditing ? handleUpdate : handleUpload}
                isLoading={
                  isLoadingOnCreate ? isLoadingOnCreate : isLoadingOnUpdate
                }
              />
            )}
          </div>

          {(text !== "" && extraTextCheck) && (
            <TextEditor
              textToEdit={text}
              setText={setText}
              isSuccess={successOnUpdate}
              />
          )}

          {(isEditing === false) && (
            <TextEditor
              textToEdit={text}
              setText={setText}
              isSuccess={successOnUpload}
              />
          )}

          {/* button visible on small screens  */}
          <div className="button-small-screen">
            <UploadButton
              enabled={file}
              userEvent={isEditing ? handleUpdate : handleUpload}
              isLoading={
                isLoadingOnCreate ? isLoadingOnCreate : isLoadingOnUpdate
              }
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddSlidePage;
