import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useQueryClient } from "react-query";

// components
import Navigation from "./partials/Navigation";
import Loader from "../components/userfeedback/Loader";
import SlideShowCard from "../components/card/SlideShowCard";
import ErrorMessage from "../components/userfeedback/ErrorMessage";

// hooks
import useDeleteslide from "../hooks/useDeleteslide";
import useSlideshow from "../hooks/useSlideshow";
import useChangeSlideOrder from "../hooks/useChangeSlideOrder";

const SlideShowPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const queryClient = useQueryClient();

  const flexBoxRef = useRef();
  const { mutate } = useDeleteslide(id);
  const { mutate: changeOrder, isLoading: isChangeOrderLoading, isSuccess: isChangeOrderSuccess } = useChangeSlideOrder();

  const { data, isLoading, isError, error } = useSlideshow(id);
  const [allSlides, setAllSlides] = useState([]);
  const [orderHasChanged, setOrderHasChanged] = useState(false);
  const [dragStartPosX, setdragStartPosX] = useState(null);
  const [dragStartPosY, setdragStartPosY] = useState(null);
  const [lastEvent, setLastEvent] = useState(null);
  const [showSavedOrderFeedback, setShowSavedOrderFeedback] = useState(false)
  const [hoverFeedbackIndex, setHoverFeedbackIndex] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const[tempArray, setTempArray] = useState([])

  useEffect(() => {
    console.log("useEffect", dataFetched)
    //if(dataFetched){
      setAllSlides(data?.Slides);
      console.log("data useEffect", data?.slides)
      //setDataFetched(true);
    //}
  }, [data]);

  useEffect(() => {
    if(showSavedOrderFeedback) return;
    setShowSavedOrderFeedback(true);
    setTimeout(() => {
      setShowSavedOrderFeedback(false);
    }, 2000);
  }, [isChangeOrderSuccess])

  useEffect(() => {
    if(allSlides?.length == 0) return;
    if(!orderHasChanged) return;

    let newOrderArray = [];
    allSlides?.forEach((element, index) => {
      newOrderArray.push({
        SlideId: element?.Id,
        SortOrder: index,
      });
    });

    changeOrder(newOrderArray, {
      onSuccess: () => {
        console.log("Updated slide order!")
        console.log(allSlides)
        queryClient.invalidateQueries("slideshow", id);
      },
      onError: () => {
        console.log("Error updating slide order")
      },
    });
  }, [allSlides]);

  const handleClick = (slideId) => {
    history.push(`/edit/${id}/slide/${slideId}`);
  };

  const loadResponse = () => {
    if (data.msg) {
      return (
        <div className="editMessage">
          <ErrorMessage width={"80%"}>
            <p>There is no slides here yet 😲 </p>
          </ErrorMessage>
        </div>
      );
    } else {
      return (
        <>
          {allSlides?.map((item, i) => {
            return (
              <div className="hover-container">
                <div className={(hoverFeedbackIndex !== null && i === hoverFeedbackIndex) ? "hover show": "hover"}></div>
                <SlideShowCard
                  data={item}
                  key={i}
                  index={i}
                  whenDragged={handleDragEvent}
                  dragStart={handleDragStart}
                  dragEnd={handleDragEnd}
                  cardEventHandlerOne={handleClick}
                  cardEventHandlerTwo={mutate}
                  page={"Edit"}
                />
                {/* {Math.floor(flexBoxRef.current.offsetWidth / 332) - 1 === i && */}
                  <div className={(hoverFeedbackIndex !== null && i + 1 === hoverFeedbackIndex) ? "hover show": "hover"}></div>
                {/* } */}
              </div>
            );
          })}
        </>
      );
    }
  };

  const handleDragStart = (e) => {
    setdragStartPosX(e.pageX);
    setdragStartPosY(e.pageY);
  };

  const handleDragEnd = (e) => {
    setdragStartPosX(null);
    setdragStartPosY(null);
    setOrderHasChanged(false);
    setAllSlides(tempArray);
    setHoverFeedbackIndex(null);
    // actually change the array here...
  };

  const handleDragEvent = (e, index) => {
    if (lastEvent == null) {
      setLastEvent(e.timeStamp);
      return;
    }
    Array.prototype.move = function (from, to) {
      this.splice(to, 0, this.splice(from, 1)[0]);
    };

    if (e.timeStamp - lastEvent > 50) {
      setLastEvent(e.timeStamp);
      let cardWidth = 332;
      let cols = Math.floor(flexBoxRef.current.offsetWidth / cardWidth);
      let [distanceX, distanceY] = nrOfSlidesMoved(e);

      let absDistX = Math.abs(distanceX);
      let absDistY = Math.abs(distanceY);

      if (
        (absDistX >= 1 || absDistY >= 1) &&
        !orderHasChanged
      ) {
        //setOrderHasChanged(true);
        const tmpArr = [...allSlides];
        let from = index;

        let movedInX = Math.sign(distanceX) * Math.floor(Math.abs(distanceX / 10)) + 1;
        let movedInY = Math.sign(distanceY) * Math.floor(Math.abs(distanceY / 10));

        let currentRow = Math.ceil((index + 1) / cols);
        let rowMultiplier = ((currentRow -1 ) * cols );

        let max = 2;
        let min = 0;
        // change min and max values based on if we are on the right or left side of the middle slide
        if(index % 3 == 0){
          min = 1;
          max = 3;
        }
        if((index + 1) % 3 == 0){
          min = -1;
          max = 1;
        }

        let to = Math.max(min, Math.min(movedInX, max)); 
        to += rowMultiplier;

        let indexOfMiddleCard = rowMultiplier + 1;
        
        if(index > indexOfMiddleCard){
          to += 1
        }else if(index < indexOfMiddleCard){
          to -= 1;
        }

        if(Math.sign(movedInY / 10) == -1){ 
          to += (movedInY * -1 * cols);
        }
        else if(Math.sign(movedInY / 10) == 1){
          to -= (movedInY * cols);
        }

        let hoverIndex = to;
        if(Math.sign(distanceX / 10) == 1){ // if x is positive highlight next index instead
          hoverIndex += 1;
        }
        
        if(to > -1){
          tmpArr.move(from, to);
          setTempArray(tmpArr);
          setHoverFeedbackIndex(hoverIndex);
        }

      }
    }
  };

  const nrOfSlidesMoved = (e) => {
    let padding = 8;
    let cardWidth = 332; // make dynamic
    let cardHeigh = 300;
    let mouseDistanceX = e.pageX - dragStartPosX;
    let mouseDistanceY = e.pageY - dragStartPosY;

    mouseDistanceY = mouseDistanceY * - 1;

    let nrOfSlidesPassedY = (mouseDistanceY * 10) / (cardHeigh + (padding * 2));
    let nrOfSlidesPassedX = (mouseDistanceX * 10) / (cardWidth + (padding * 2));

    let dX =
      Math.sign(nrOfSlidesPassedX) * Math.floor(Math.abs(nrOfSlidesPassedX));
    let dY =
      Math.sign(nrOfSlidesPassedY) * Math.floor(Math.abs(nrOfSlidesPassedY));   


    let signY = Math.sign(dY);

    if (signY == 1) { // If the slide is moved upwards
      // in increments of 10, get the current position of the moved slide
      let yFloored = Math.floor(dY / 10) * 10;
      yFloored += signY * 5;
      // If slide has moved half a slide length, "fake" having moved one slide length to highlight the hoverfeedback above
      if (dY > yFloored) {
        dY = Math.ceil(nrOfSlidesPassedY / 10) * 10;
      }
    } else if (signY == -1) { // If the slide is moved downwards
      let yCeiled = Math.ceil(dY / 10) * 10;
      yCeiled += signY * 5;
      if (dY < yCeiled) {
        dY = Math.floor(nrOfSlidesPassedY / 10) * 10;
      }
    }

    return [dX, dY];
  };

  const savingSlideOrderText = () =>{
    if(!lastEvent) return;
    if(showSavedOrderFeedback) return <p>Slide order changed!</p>
    return null;
  }

  return (
    <>
      <Navigation />
      {isError && <ErrorMessage width={"80%"}>{`${error}`}</ErrorMessage>}
      {isLoading && <Loader />}
      {data && (
        <div className="edit-container">
          <div className="control-bar">
            <div className="summary">
              <p>
                Id: <span>{id}</span>
              </p>
              <p>
                Code: <span>{data.Code}</span>
              </p>
              <p>
                Made by: <span>{data.Author}</span>
              </p>
              {savingSlideOrderText()}
            </div>
            <div>
              <Link to={`/create/${id}`}>
                <button>Make more slides? </button>
              </Link>
              <Link to={`/slideshow/${id}`}>
                <button>Play</button>
              </Link>
            </div>
          </div>
          <div ref={flexBoxRef} className="slideShowCardWrapper">
            {loadResponse()}
          </div>
        </div>
      )}
    </>
  );
};

export default SlideShowPage;
