const UploadButton = ({ enabled, userEvent }) => {
  return (
    <>
      <input
        type="button"
        disabled={!enabled}
        onClick={userEvent}
        value="Save"
      />
      
    </>
  );
};

export default UploadButton;
