import { useQuery } from "react-query";
import { getSlideByCode } from "../services/SlideShowsApi";


const useReadSlideShowByCode = (code) => {
    
return useQuery(['slideshow', code], () => getSlideByCode(code))


}
 
export default useReadSlideShowByCode;