import { useState, useEffect } from "react";
import { useHistory } from "react-router";
//layout
import FormContainer from "../components/layout/FormContainer";
// components
import ErrorMessage from "../components/userfeedback/ErrorMessage";
import Navigation from "./partials/Navigation";
import UserForm from "../components/forms/UserForm";
import { useQueryClient } from "react-query";
// hooks
import useReadSlideShowByCode from "../hooks/useReadSlideShowbyCode";

import { Link } from "react-router-dom";

const UseCodePage = () => {
  const [slideshowCode, setSlideshowCode] = useState(null);

  const history = useHistory();
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess, isError } =
    useReadSlideShowByCode(slideshowCode);

  useEffect(() => {
    if (isSuccess && data) {
      history.push(`/slideshow/${data}`);
    }
  }, [isSuccess]);

  const handleSubmit = (code) => {
    setSlideshowCode(code);
    // invalide query if it already exists
    queryClient.resetQueries(["slideshow", code]);
  };

  return (
    <>
      <Navigation />
      <div className="loginPageWrapper">
        <h2 className="text-center my-4">Consids Infotainmentsystem</h2>
        {isError && (
          <ErrorMessage>
            Slideshow does not exist, try another code or
            <Link to="login"> login here</Link>
          </ErrorMessage>
        )}

        <FormContainer>
          <UserForm
            isLoading={isLoading}
            handleUserEvent={handleSubmit}
            page={{ currentPage: "Enter code", action: "Go to Slideshow" }}
          />
        </FormContainer>
      </div>
    </>
  );
};

export default UseCodePage;
