import { useQuery } from "react-query";
import { getMeetings } from "../services/MeetingsApi";

const useGetMeetings = (location) => {
  return useQuery("meetings", () => getMeetings(location), {
    refetchInterval: 60000 * 30,
  });
};

export default useGetMeetings;
