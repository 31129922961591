import { useEffect } from "react";

//hooks
import useGallery from "../../hooks/useGallery";

// data fetching
import { useQueryClient } from "react-query";

//components
import ErrorMessage from "../userfeedback/ErrorMessage";
import GalleryCard from "../card/GalleryCard";
import Loader from "../userfeedback/Loader";



const History = ({ userEvent, setIndex, fileNameFilter }) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, } = useGallery();

  useEffect(() => {
    queryClient.invalidateQueries("images");
  }, []);
  const saveImageToSlide = (image) => {
    userEvent(image);
    // when user have selected an image, return to upload view.
    setIndex(0);
  };

  return (
    <div className="gallery-wrapper">
      {isError && (
      <ErrorMessage>Something went wrong</ErrorMessage>
      )}
      {isLoading && <Loader />}

      {data &&
        data.filter(data => data.Url !== fileNameFilter).map((item, i) => {
          return (
            <GalleryCard
              key={i}
              item={item}
              saveImageToSlide={saveImageToSlide}
            />
          );
        })}
    </div>
  );
};

export default History;
