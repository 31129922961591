import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Calendar = ({ startDate, setStartDate, endDate, setEndDate, resetValues }) => {
  return (
    <>
    <div className="calendar">
      <DatePicker
      className="datepicker"
        selected={startDate}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="MMMM d, yyyy HH:mm"
        onChange={(date) => setStartDate(date)}
        placeholderText="Startdate and time"
        withPortal
      />
      <DatePicker
         className="datepicker"
        selected={endDate}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm"
        onChange={(date) => setEndDate(date)}
        placeholderText="Enddate and time"
        withPortal
      />
    </div>
      <span style={{marginRight: '10 px'}} onClick={() => resetValues()}>Reset values?</span>
    </>
  );
};

export default Calendar;
