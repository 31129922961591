import UploadButton from "./UploadButton";

const ExternalWebpage = ({ setWebsite, userEvent }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        userEvent();
    };

    return (
        <>
            <form className="meetingForm" onSubmit={handleSubmit}>
                <input type="text" name="website" placeholder="URL" onChange={(e) => setWebsite(e.target.value)} />
                {/* <UploadButton
                    enabled={true}
                    userEvent={handleSubmit}
                //isLoading={isLoading}
                /> */}
            </form>
        </>
    );
};

export default ExternalWebpage;
