import { useQuery, useQueryClient } from "react-query";
import { getAllSlideShows } from "../services/SlideShowsApi";

const useSlideshows = () => {
  //const queryClient = useQueryClient();
  //queryClient.refetchQueries(["slideshows"], { stale: true });

  return useQuery(["slideshows"],getAllSlideShows, {
    keepPreviousData: true,
  });
};

export default useSlideshows;
