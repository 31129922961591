import axios from "axios";
import { base } from "./defaults";

axios.defaults.baseURL = base;

export const get = async (endpoint) => {
  const res = await axios.get(endpoint);

  return res.data;
};
// endpoint for getting office locations
export const getOfficeLocations = async () => {
  const res = await get("/OfficeLocations");

  return res;
};

export const getMeetings = async (officeLocation) => {
  if (!officeLocation) return;
  const res = await get(`/Meetings?officeLocation=${officeLocation}`);

  return res;
};

export const getFreeRooms = async (officeLocation) => {
  if (!officeLocation) return;
  const res = await get(`/Freerooms?officeLocation=${officeLocation}`);

  return res;
};
