import { useMutation, useQueryClient } from "react-query";
import {updateSlideshowTimer} from "../services/SlideShowsApi"


const useUpdateSlideshow = () => {
    const queryClient = useQueryClient();
    return useMutation(updateSlideshowTimer, {
        onSuccess: () => {
            queryClient.invalidateQueries("slideshows");
          },
    })
}
 
export default useUpdateSlideshow;