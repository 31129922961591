import { prefix } from "../../services/defaults";


const GalleryCard = ({ item, saveImageToSlide }) => {

  return (
     <div onClick={() => saveImageToSlide(item)}
      className="gallery-card background-image" 
      style={{  backgroundImage: item.hasOwnProperty('regular') ? `url(${item.thumb})` : `url(${prefix}${item.Url}` }}
    ></div>
  );
};

export default GalleryCard;
