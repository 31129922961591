export const add_slide_navigation = [
    { name: "Slide" },
    { name: "History" },
    { name: "Explore" },
    { name: "Meetings" },
    { name: "External Website" },
];

export const settings_navigation = [
    { name: "Add Names" },
    { name: "Manage Slideshows" },
];
