import useSlideshows from "../../hooks/useSlideshows";
import useUpdateSlideshow from "../../hooks/useUpdateSlideshow";
import TimerInput from "../forms/TimerInput";
import ErrorMessage from "../userfeedback/ErrorMessage";
import UserFeedback from "../userfeedback/UserFeedback";
import Loader from "../userfeedback/Loader";
import SlideshowText from "../forms/SlideshowTextProperty";
import { useEffect, useState } from "react";

const Timers = () => {
  const {
    data,
    isError: isErrorOnRead,
    isLoading: isOnLoadingOnRead,
  } = useSlideshows();

  const updateSlideshow = (index, item) => {
    const slideshowName = document.querySelector(
      `input[data-title="${index}"]`
    ).value;
    const slideshowDescription = document.querySelector(
      `input[data-description="${index}"]`
    ).value;
    const slideshowAuthor = document.querySelector(
      `input[data-author="${index}"]`
    ).value;

    const timer = document.querySelector(`input[data-timer="${index}"]`).value;

    const data = {
      ...item,
      Timer: parseInt(timer),
      Title: slideshowName,
      Description: slideshowDescription,
      Author: slideshowAuthor,
    };

    updateTimer(data);
  };

  const {
    mutate: updateTimer,
    isLoading,
    isSuccess,
    isError: isErrorOnUpdate,
  } = useUpdateSlideshow();
  const submitForm = (e, { item, inputValue, inputRef }) => {
    e.preventDefault();

    const data = {
      ...item,
      Timer: parseInt(inputValue),
    };

    updateTimer(data);

    //unfocus from input fild after success full update.
    inputRef.current.blur();
  };

  return (
    <div className="timers-container">
      <UserFeedback isLoading={isLoading} isSuccess={isSuccess} />
      {isErrorOnRead && <ErrorMessage>Something went wrong</ErrorMessage>}
      {isErrorOnUpdate && <ErrorMessage>Something went wrong</ErrorMessage>}
      {isOnLoadingOnRead && <Loader />}
      {data && (
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="th-center" scope="col">
                #
              </th>
              <th className="th-center" scope="col">
                Title
              </th>
              <th className="th-center" scope="col">
                Description
              </th>
              <th className="th-center" scope="col">
                Author
              </th>
              <th className="th-center" scope="col">
                Timer
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i}>
                  <th scope="row">{++i}</th>
                  <td>
                    <input
                      data-title={i}
                      style={{ width: "100%" }}
                      type="text"
                      defaultValue={item.Title}
                    />
                  </td>
                  <td>
                    <input
                      data-description={i}
                      style={{ width: "100%" }}
                      type="text"
                      defaultValue={item.Description}
                    />
                  </td>
                  <td>
                    <input
                      data-author={i}
                      style={{ width: "100%" }}
                      type="text"
                      defaultValue={item.Author}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      data-timer={i}
                      min="10"
                      max="500"
                      defaultValue={item.Timer}
                    />
                  </td>
                  <td>
                    <button
                      style={{ width: "100%" }}
                      className="button"
                      onClick={() => updateSlideshow(i, item)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Timers;
