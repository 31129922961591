import { useHistory } from "react-router";
import { createSlideshowId } from "../services/SlideShowsApi";

import { useMutation, useQueryClient } from "react-query";

const useCreateSlideshow = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(createSlideshowId, {
    onSuccess: ({ Id }) => {
      // when data comes back - go to the specfic sliedshowid page where you can creaate slides for that specific slideshow
      queryClient.invalidateQueries("slideshows");
      history.push(`/create/${Id}`);
    },
  });
};

export default useCreateSlideshow;
