import useGetFreeRooms from "../../hooks/useGetFreeRooms";

const Events = ({ location, color }) => {
  const { data, isError, isLoading } = useGetFreeRooms(location);
  return (
    <div className="bookingsWrapper" style={{ backgroundColor: color }}>
      <div className="info">
        <h1>Lediga rum just nu</h1>
        {/* <h4>Slå dig gärna ner i loungen medan du väntar</h4> */}
        {data && data.length === 0 && (
          <img src="/consid_logo.png" alt="consid logo" />
        )}
        <ul>
          {data?.map((item, i) => {
            
            return (
              <li
                key={i}
                style={{ fontSize: data?.length > 10 ? "1.5em" : "2em" }}
              >
                {`${item.Room} ${item.Text !== "" ? '-' : ""} ${item.Text} ${item.Time}`}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Events;
