import { useEffect, useRef } from "react";

import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../utils/config_texteditor";

const TextEditor = ({ textToEdit, setText, isSuccess }) => {
  const instanceRef = useRef();
  useEffect(() => {
    if (isSuccess) {
      destroy();
    }
  }, [isSuccess]);

  // when slide is saved successfully, clear the instance
  const destroy = async () => {
    if (!instanceRef) return;
    await instanceRef.current.clear();
  };

  const handleChange = async () => {
    if (!instanceRef) return;
    const savedData = await instanceRef.current.save();
    if(savedData.blocks.length !== 0){
      setText(savedData);
    }
  };

  return (
    <>
      <div className="text-editor">
        <EditorJs
          onChange={() => handleChange()}
          instanceRef={(instance) => (instanceRef.current = instance)}
          tools={EDITOR_JS_TOOLS}
          minHeight="10"
          placeholder="Click on me to start writing"
          data={textToEdit}
          onReady={() => {
            if (textToEdit) {
              instanceRef.current.render(textToEdit);
            }
          }}
        />
      </div>
    </>
  );
};

export default TextEditor;
