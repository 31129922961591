const FileUploader = ({ setFile, isEditing }) => {
  const handleChange = (e) => {
    if (e.target.files.length === 1) {
      setFile(e.target.files[0]);
    } else {
      setFile(e.target.files);
    }
  };

  return (
    <>
      <div className="file-uploader">
        <label htmlFor="file-input">Select file</label>
        <input
          id="file-input"
          type="file"
          multiple={isEditing ? false : true}
          accept="image/png, image/jpeg"
          onChange={(e) => handleChange(e)}
        />
      </div>
    </>
  );
};

export default FileUploader;
