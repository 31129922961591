import axios from "axios";
import { base } from "./defaults";

axios.defaults.baseURL = base;

const get = async (endpoint) => {
  const res = await axios.get(endpoint);

  return res.data;
};

// get all user
export const getAllKnownVisitors = async () => {
  const res = await get("/Associates");

  return res.reverse();
};
// get an user ??

// create an user
export const createKnownVisiter = async (data) => {
  const res = await axios.post("/Associate", data);

  return res;
};

// delete an user

export const deleteUserById = async (id) => {
  await axios.delete(`/Associate/${id}`);
};
