import { Link } from "react-router-dom";
import { useParams } from "react-router";

// components
import ErrorMessage from "../components/userfeedback/ErrorMessage";
import Loader from "../components/userfeedback/Loader";
import Slides from "../components/slides/SlidesContainer";

// hooks
import useSlideshow from "../hooks/useSlideshow";

const PlaySlideShow = () => {
  const { id } = useParams();

  const { data, isError, isLoading } = useSlideshow(id)
  

  if (isError)
    return (
      <ErrorMessage width={"80%"}>
        Sorry, this slideshow doesn't exist
        <br />
        <Link to="/slideshows">Go back to homepage</Link>
      </ErrorMessage>
    );

  if (isLoading) return <Loader />;

  if (data.msg)
    return (
      <ErrorMessage width={"80%"}>
        <h3>There is no slides here 😲 </h3>
        <br />
        <Link to="/slideshows">Go back to homepage 🏠 </Link>
      </ErrorMessage>
    );

  if (data) return <Slides response={data.Slides} timer={data.Timer} />;
};

export default PlaySlideShow;
