//navigation
import { Route, Switch } from "react-router-dom";
import axios from "axios"

// Pages
import HomePage from "./pages/HomePage";
import PlaySlideShow from "./pages/PlaySlideShow";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import CreateSlideShowPage from "./pages/CreateSlideShowPage";
import AddSlidePage from "./pages/AddSlidePage";
import SlideShowPage from "./pages/SlideShowPage";
import UseCodePage from "./pages/UseCodePage";
import SettingsPage from "./pages/SettingsPage";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

function App() {
  const { instance, accounts } = useMsal();

  if (accounts[0]) {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(token => {
      //console.log("Token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token.idToken}`;
    });
  }


  return (
    <div className="app">
      <Switch>
        <Route path="/edit/:id/slide/:slideId">
          <AddSlidePage />
        </Route>
        <Route path="/edit/:id">
          <SlideShowPage />
        </Route>
        <Route path="/create/:id">
          <AddSlidePage />
        </Route>
        <Route exact path="/create">
          <CreateSlideShowPage />
        </Route>
        <Route path="/slideshow/:id">
          <PlaySlideShow />
        </Route>
        <Route exact path="/slideshows">
          <HomePage />
        </Route>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route path="/settings">
          <SettingsPage />
        </Route>
        <Route exact path="/">
          <UseCodePage />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
