


const ColorPicker = ({color, setColor }) => {
    return (
        <div>
            <span className="colorPickerText">Background color: </span>
            <input
                id="color"
                type="color"
                name="backgroundColor"
                className="colorPicker"
                value={color}
                onChange={(e) => setColor(e.target.value)}
            />
        </div>
    );
};

export default ColorPicker;