const FormButton = ({ value, userEvent, disable }) => {

  return (
    <button type="submit" disabled={disable} onSubmit={userEvent}>
      {value}
    </button>
  );
};

export default FormButton;
