import { useEffect, useState, useRef } from "react";
import useCreateKnownVisiter from "../../hooks/useCreateKnownVisiter";
import FormButton from "./FormButton";

// helpers
import { replaceSpecialChars } from "../../utils/helpers/replace_special_chars";

const AddKnownNamesForm = () => {
  const [disable, setDisable] = useState(true);
  const { mutate: createUser } = useCreateKnownVisiter();

  const inputRef = useRef();

  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [email, setEmail] = useState("");

  useEffect(() => {
    const { one, two, three, four } = inputs;
    // when all fields ha a value, undisable the button
    if (one !== "" && two !== "" && three !== "" && four !== "") {
      setDisable(false);
    }
  }, [inputs]);

  useEffect(() => {
    const { one, two, three } = inputs;
    // when all fields ha a value, undisable the button
    if (one !== "" || two !== "" || three !== "") {
      setEmail(`${one}.${two}@${three}.com`);
    }
  }, [inputs]);

  useEffect(() => {
    const replaced = replaceSpecialChars(email);

    setInputs({ ...inputs, four: replaced });
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      FirstName: inputs.one.trim(),
      LastName: inputs.two.trim(),
      Company: inputs.three.trim(),
      Email: inputs.four.trim(),
    };

    createUser(data);

    inputRef.current.focus();

    setDisable(true);
    setInputs({
      one: "",
      two: "",
      three: "",
      four: "",
    });
  };

  return (
    <form
      className="add-knownnames-form"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <input
        ref={inputRef}
        type="text"
        name="one"
        placeholder="Daniel"
        value={inputs.one}
        onChange={(e) => handleChange(e)}
        required
      />
      <input
        type="text"
        name="two"
        placeholder="Palmdal"
        value={inputs.two}
        onChange={(e) => handleChange(e)}
        required
      />
      <input
        type="text"
        name="three"
        placeholder="Consid"
        value={inputs.three}
        onChange={(e) => handleChange(e)}
        required
      />
      <input
        type="email"
        name="four"
        placeholder="daniel.palmdal@consid.se"
        value={inputs.four}
        onChange={(e) => handleChange(e)}
        required
      />
      <FormButton value={"Save"} disable={disable} userEvent={handleSubmit} />
    </form>
  );
};

export default AddKnownNamesForm;
