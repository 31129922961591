const emailIsValid = (emailToValidate) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(emailToValidate.length < 50){
        if (emailToValidate.match(regex)) {
            return true;
        }
    }
    return false;
};

export const parseEmail = (param) => {
    // output ex: Jane Doe Consid
    if (emailIsValid(param)) {
        let [name, email] = param.split("@");
        let [firstName, lastName, extra] = name.split(".");
        let [companyName, rest] = email.split(".");

        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
        if (lastName !== undefined && extra === undefined) {
            lastName = lastName?.charAt(0).toUpperCase() + lastName?.slice(1);
        }else if(lastName !== undefined && extra !== undefined){
            lastName = lastName?.charAt(0).toUpperCase() + " " + extra.charAt(0).toUpperCase() + extra?.slice(1);
        }
        companyName =
            companyName.charAt(0).toUpperCase() + companyName.slice(1);

        if(companyName === "Gmail" || companyName === "Hotmail" || companyName === "Outlook"){
            companyName = "";
        }

        if (lastName === undefined) {
            return `${firstName} ${companyName}`;
        }
        return `${firstName} ${lastName} ${companyName}`;
    }

    return null;
};
